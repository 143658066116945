import React from 'react';
import { TextField, FormHelperText, Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from '../../Steppers/AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';
import { getSpeedUnitString, convertUnitValueAsPerUserSetting } from '../../../../helper-classes/utility-functions';
import { validateRealert, validateSpeedLimit } from '../../../../containers/Alerts/Steppers/getRequestPayload';

type Props = {
    formData:{
        config:{
            speedLimitExceedMPH: Number,
            realertMinutes: Number,
            realert: Boolean,
            skipFirstOccurrence:Boolean,
        },
    },
    handleCheckboxChange: Function,
    handleTextboxChange: Function,
    validationError: String,
};

class PostedSpeedLimitAlert extends React.Component<Props> {
    state = {
        unit: '',
    };
    UNSAFE_componentWillMount() {
        const unit = getSpeedUnitString();
        this.setState({
            unit,
        });
    }

    render() {
        let { speedLimitExceedMPH = 0 } = this.props.formData.config;
        let speedLimitErrorMsg = '';
        let realertMinutesErrorMsg = '';
        if (speedLimitExceedMPH) {
            speedLimitExceedMPH = Math.round(speedLimitExceedMPH);
        }
        if (this.props.validationError) {
            speedLimitErrorMsg = validateSpeedLimit(this.props.formData.config);
            realertMinutesErrorMsg = validateRealert(this.props.formData.config);
        }
        const minValue = convertUnitValueAsPerUserSetting(ALERT_DETAILS.VALUE.PSL_MIN, 'round');
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>{ALERT_DETAILS.LABEL.POSTED_SPEED_LIMIT}</div>
                            <div>
                                <TextField
                                    required
                                    margin="normal"
                                    className={styles['text-wid']}
                                    id="speedLimitExceedMPH"
                                    name="speedLimitExceedMPH"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: minValue,
                                            max: ALERT_DETAILS.VALUE.PSL_MAX,
                                        },
                                    }}
                                    value={speedLimitExceedMPH}
                                    onKeyPress={(event) => {
                                        if (event.charCode === 46) {
                                            event.preventDefault();
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onChange={(e) => {
                                        let inputValue = e.target.value;
                                        if (inputValue !== '') {
                                            inputValue = (Math.trunc(inputValue)).toString();
                                            if (inputValue.indexOf('.') !== -1) {
                                                inputValue = inputValue.substring(1, inputValue.indexOf('.'));
                                            }
                                        }
                                        this.props.handleTextboxChange('speedLimitExceedMPH')({
                                            target: {
                                                value: inputValue,
                                            },
                                        });
                                    }}
                                />
                                <span className={styles['unit-Name']}>{this.state.unit}*</span>
                                <FormHelperText style={{ color: 'red' }}>
                                    {speedLimitErrorMsg}
                                </FormHelperText>
                                <div className={styles['txt-pad']}>
                                    {ALERT_DETAILS.LABEL.POSTED_SPEED_ALERT_CONTINUOUS_VIOLATION}
                                </div>
                                <FormControlLabel
                                    className={styles['top-pad']}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            id="firstOccurrence"
                                            name="firstOccurrence"
                                            checked={
                                                this.props.formData.config
                                                    .skipFirstOccurrence === true
                                            }
                                            onChange={(e) => {
                                                this.props.handleCheckboxChange('skipFirstOccurrence')(e);
                                            }}
                                        />
                                    }
                                    label={ALERT_DETAILS.LABEL.POSTED_SPEED_FIRST_OCCURRENCE}
                                />
                            </div>
                            <div className={styles['inn-pad']}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            id="realert"
                                            name="realert"
                                            checked={
                                                this.props.formData.config.realert === true
                                            }
                                            onChange={(e) => {
                                                this.props.handleCheckboxChange('realert')(e);
                                                if (!e.target.checked) {
                                                    this.props.handleTextboxChange('realertMinutes')({
                                                        target: {
                                                            value: ALERT_DETAILS.VALUE.PSL_R_DEF,
                                                        },
                                                    });
                                                }
                                            }}
                                        />
                                    }
                                    label={ALERT_DETAILS.LABEL.POSTED_SPEED_REALERT}
                                />
                            </div>
                            <div>
                                <TextField
                                    required
                                    margin="normal"
                                    className={styles['text-wid']}
                                    id="realertMinutes"
                                    name="realertMinutes"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: ALERT_DETAILS.VALUE.PSL_R_MIN,
                                            max: ALERT_DETAILS.VALUE.PSL_R_MAX,
                                        },
                                    }}
                                    disabled={!this.props.formData.config.realert}
                                    value={this.props.formData.config.realertMinutes !== undefined
                                        ? this.props.formData.config.realertMinutes
                                        : ALERT_DETAILS.VALUE.PSL_R_DEF}
                                    onChange={this.props.handleTextboxChange('realertMinutes')}
                                />
                                <span className={styles['unit-Name']}>{ALERT_DETAILS.LABEL.POSTED_SPEED_MINUTES}</span>
                                <FormHelperText style={{ color: 'red' }}>
                                    {realertMinutesErrorMsg}
                                </FormHelperText>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default PostedSpeedLimitAlert;
